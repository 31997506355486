import React from 'react';
import './App.css'; // Import your CSS file here
import Header from './Header';
import Footer from './Footer';
import './Whitepaper.css';


function Softie() {
  return (
    <div className="Whitepaper">
      <Header />
      <div className="whitepaper-content">
        <h1>Softie</h1>
        <p>Your Exclusive Opportunity to Earn and Grow with Us.</p>
        <p style={{ fontSize: '12px', background: '#041f4c', color: '#48e4fa', padding: '20px 10px', borderRadius: '5px', boxShadow: '0px 0px 40px rgba(135,224,254,0.495)'}}>As a member of the Softcoin community, we are giving you an exclusive opportunity to become a shareholder in the project. This is your chance to share in the profits we generate daily.</p>
    <section className="section">
      <h2>Becoming A Shareholder</h2>
      <p>To become a Shareholder, you simply need to deposit a token into the project trading portfolio (this is your commitment). You immediately become a Shareholder and you start getting a share of the profit generated by the project everyday. </p>
    </section>
<section className="section">
    <h2>Shareholder Structure</h2>

    <p>There are six levels of Shareholder, and your level is determined by your commitment amount, And each level gives you a different daily income percentage.</p>

    <h2>Softie Levels</h2>
    <p><strong>Level 1 (Rising Softie)</strong>: This is the lowest Shareholder level, for users with commitment between $5 and $30. it comes with a daily return of 1.5%, and you can earn $0.50 per day if you commited $30. It’s an excellent starting point to test the waters and eventually level up for bigger returns.</p>

     <p><strong>Level 2 (Junior Softie)</strong>: For users who committed between $31 and $100. This tier offers a 2% daily return, potentially yielding up to $2 per day.</p>

     <p><strong>Level 3 (Pro Softie)</strong>: This level caters to users with commitments between $101 and $500. It comes with a 2.5% daily return, translating to earnings of up to $12.5 per day.</p>

     <p><strong>Level 4 (Expert Softie)</strong>: Users in this tier have committed between $501 and $2,000 and enjoy a 3% daily return. At this level, users can earn up to $60 per day.</p>

       <p><strong>Level 5 (Master Softie)</strong>: This is the second-highest Shareholder level, and its for users who have committed between $2,001 and $5,000. This level offers a 3.5% daily return, which can amount to up to $175 per day.</p>

       <p><strong>Level 6 (Legend Softie)</strong>: This is the highest Shareholder Level, for users who committed more than $5,000. Legend Softies benefit from a 4% daily return, potentially earning 150% of their commitment within a month. The daily earning potential for users at this level is unlimited.</p>

      <p>The Softie initiative is structured to accommodate users at all financial levels, ensuring that anyone, regardless of their commitment, has an opportunity to participate and earn.</p>
</section>

<section className="section">
    <h2>Commitment Deadline</h2>
    <p>Participants have until December 20th, 2024, to make their commitment. After this date, no further commitments will be accepted, as we will be just eight months away from our scheduled Token Generation Event (TGE).</p>

    <p>By that time, we would have transitioned from our awareness campaign to building the core currency infrastructure.</p>

    <h2>Commitment Refunds and Long-Term Benefits</h2>
    <p>Once the token become listed on major exchange platforms, all commitments will be unlocked and fully refunded to users.</p>
    <p>However, this does not mark the end of your earnings and benefits as a shareholder. Shareholders will continue to receive a monthly return based on their Softie level. This return will be distributed for as long as the token remains active in the cryptocurrency market.</p>
</section>

<section className="section">
    <h2>How do we make money?</h2>
    <p>The Softcoin project is developed and managed by a team of experienced crypto traders with a proven track record of profitability. Our funding and revenue are driven primarily by our trading activities, and we are committed to sharing these profits with those who are willing to grow with us.</p>
    <p>The amount deposited by each user is added to our trading portfolio, and as we generate profits through our trades, every shareholder will receive their promised daily return.</p>
    <p>Beside our crypto trading activity, we generate more revenue from ads. We also get revenue from donors who could see a bright future in the project and wishes to contribute to our growth for a certain percentage of the token supply.</p>

</section>

<section className="section">
    <h2>The Vision</h2>
    <p>Our vision for Softcoin is to create a lasting and valuable cryptocurrency asset, and this is why Softcoin will be launched as a DAO (Decentralized Autonomous Organization). Building a successful DAO requires robust blockchain infrastructure, which takes time and significant resources to develop. This is why our Token Generation Event is set for one year after the project’s inception.</p>

    <p>While building a DAO takes more time and resources, it is the most reliable path to creating a long-lasting and authoritative cryptocurrency. This means dedicated community members will ultimately realize more profit from Softcoin than from typical memecoins or GameFi projects.</p>

</section>

<section style={{ textAlign: 'center', marginBottom: '30px'}} class="section">
    <h2 style={{ textAlign: 'left'}}>Conclusion</h2>
    <p style={{ textAlign: 'left'}}>Softie presents a rare opportunity to become a shareholder in a project that prioritizes long-term value and community involvement. By making a commitment now, you can secure your place in the Softcoin ecosystem and enjoy daily returns as we grow.</p>

    <a className="register-button" href="https://app.softcoin.world">Join Now</a>
</section>
              <Footer />
       </div>
    </div>
  );
}

export default Softie;